import logo from './paywclogo.png';
import './App.css';
import Holidays from 'date-holidays';
var hd = new Holidays("GB")

function App() {

  const isNonWorkingDay = (date) => {
    if (date.getDay() !== 6 && date.getDay() !== 0) {
      if (!hd.isHoliday(date)) {
        return true;
      }
    }
    return false;
  }

  const getLastWorkingDay = (date) => {
    const year = date.getFullYear()
    const month = date.getMonth()
    var lastWorkingDay = date.getDate()
    while (lastWorkingDay >= 0) {
      var nd = new Date(year, month, lastWorkingDay);
      if (isNonWorkingDay(nd)) {
        return lastWorkingDay;
        break;
      }
      lastWorkingDay -= 1;
    }
  }

  const getPayDay = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    if (month == 11) {
      return 24
    }
    var d = new Date(year, month + 1, 0);
    const lastWorkingDay = getLastWorkingDay(d)
    var nd = new Date(year, month, lastWorkingDay - 1);
    const penultimateWorkingDayDate = getLastWorkingDay(nd)

    return penultimateWorkingDayDate
  }

  const payDay = getPayDay()
  const date = new Date()
  const payDayDate = payDay + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  const daysUntilPayDay = payDay - date.getDate()

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} style={{ width: "20%" }} alt="logo" /> */}
        <h2>How long until payday?</h2>
        <h1>{daysUntilPayDay + " days"}</h1>
        <p style={{ fontSize: '0.7em' }}>{"Next pay day is " + payDayDate}</p>
        <p style={{ fontSize: '0.5em' }}>We're paid on the penultimate working day of each month, excluding December when we're paid on the 24th</p>

        <br />
        <br />

        <a style={{ fontSize: '0.5em' }} target="_blank" href="https://www.linkedin.com/in/chris-lee-483a07102">Issues? Message</a>
        <br />
        {/* <p style={{ fontSize: '0.3em' }}>Site is not affiliated with PricewaterhouseCoopers etc etc.</p> */}
      </header>

    </div>
  );
}

export default App;
